import { type InjectionKey } from 'vue'
import { type Store } from 'vuex'
import { synchronizeStoreField } from '../storeHelpers'
import { Query, type Matholder } from '@avvoka/editor'
import type { EditorStoreType } from '@stores/features/editor.store'

export type OperationID = 'GENERATED_UUID_OF_OPERATION'

export interface IOperation {
  uuid: OperationID
  att: string
  cond: string
  // desc: string
}

export interface IOperationsStore {
  operations: Array<IOperation>
  questions: Array<Backend.Questionnaire.IQuestion>
  selected_operation: IOperation | null
  showSidebar: boolean
  ast: string
  complexMode: boolean
  operationName: string
  operationSearch: string
}

export const recomputeOperations = () => {
  if ('AvvStore' in window && EditorFactory.exists('editor')) {
    const bridge = EditorFactory.getEntry(EditorFactory.main.options.id!).get().bridge as EditorStoreType
    const matholders = new Set(
      EditorFactory.main.query(Query<Matholder>('matholder').WithoutAttributes('data-expression')).map((blot) => blot.node?.textContent).filter((v) => v) as string[]
    )
  
    const operations = bridge.operationsLibrary
    for (const attr of matholders) {
      // If operation does not exist in current template operations and exists in library
      let operation
      if (AvvStore.state.operations.every((op) => op.att !== attr) && (operation = operations.find((op) => op.name === attr))) {
        AvvStore.state.operations.push({
          att: attr,
          cond: operation.text,
          uuid: Ast.generateUUID()
        })
      }
    }
  }
}

const actions = {
  selectOperation({ commit }, operation: IOperation | OperationID) {
    if (typeof operation === 'string') commit('SELECT_OPERATION', operation)
    else commit('SELECT_OPERATION', operation.uuid)
  }
}

const getters = {
  mappedOperations(state: IOperationsStore): Record<OperationID, IOperation> {
    return state.operations.reduce(
      (obj, q) => ((obj[q.uuid] = q), obj),
      Object.create(null)
    )
  },
  nonDeletedQuestions(
    state: IOperationsStore
  ): Array<Backend.Questionnaire.IQuestion> {
    return state.questions.filter((q) => !q.deleted)
  },
  selectedOperation(state: IOperationsStore, getters): IOperation | null {
    return getters.mappedOperations[state.selected_operation]
  },
  ast(state: IOperationsStore, getters): string | null {
    const selectedOperation = getters.selectedOperation
    return selectedOperation ? selectedOperation.cond : null
  }
  // description(state, getters): string | null {
  //   const selectedOperation = getters.selectedOperation
  //   return selectedOperation ? selectedOperation.desc : null
  // }
}

const mutations = {
  SELECT_OPERATION(state: IOperationsStore, uuid: OperationID) {
    state.selected_operation = uuid
    if (!state.showSidebar) state.showSidebar = true
  },
  SET_OPERATION_AST(
    state: IOperationsStore,
    { uuid, ast }: { uuid: OperationID; ast: string }
  ) {
    const operation = state.operations.find((op) => op.uuid === uuid)
    if (operation) {
      operation.cond = ast
    }
  },
  CREATE_OPERATION(state: IOperationsStore, operationName?: string) {
    const uuid = Ast.generateUUID()
    const newOperationName = (i) => {
      const name = `${localizeText('general.operation')} ${i}`
      if (state.operations.some((op) => op.name === name))
        return newOperationName(i + 1)
      else return name
    }
    if (!operationName)
      operationName = newOperationName(state.operations.length + 1)
    state.operations.push({ uuid, cond: "{'ast':{}}", att: operationName })
  },
  DUPLICATE_OPERATION(state: IOperationsStore, uuid: string) {
    const operation = state.operations.find((op) => op.uuid === uuid)

    if (!operation) return

    let newName = ''
    const baseName = operation.att.replace(/\s+\((\d+|copy)\)$/, '')
    const copyRegex = /\s+\((copy)\)$/
    const numberRegex = /\s+\((\d+)\)$/

    if (copyRegex.test(operation.att)) {
      newName = `${operation.att} (copy)`
    } else if (numberRegex.test(operation.att)) {
      const numberMatch = operation.att.match(numberRegex)
      if (numberMatch) {
        const copyNumber = parseInt(numberMatch[1]) + 1
        newName = `${baseName} (copy)(${copyNumber})`
      }
    } else {
      newName = `${operation.att} (copy)`
    }

    // Ensure the new name is unique
    let finalName = newName
    let counter = 2
    while (state.operations.some((op) => op.att === finalName)) {
      if (copyRegex.test(newName)) {
        finalName = `${baseName} (copy)(${counter})`
      } else {
        finalName = `${newName}(${counter})`
      }
      counter++
    }

    const newOperation = {
      ...operation,
      uuid: Ast.generateUUID(),
      att: finalName
    }
    state.operations.push(newOperation)
  },
  REMOVE_OPERATION(state: IOperationsStore, uuid: string) {
    state.operations = state.operations.filter((op) => op.uuid !== uuid)
    if (state.showSidebar) state.showSidebar = false
  },
  SET_AST(state, ast: string) {
    state.ast = ast
  },
  SET_COMPLEX_MODE(state, bool: boolean) {
    state.complexMode = bool
    window.localStorage.setItem('operations-complex-mode', String(bool))
  },
  SET_OPERATION_NAME(state, newName: string) {
    state.operationName = newName
  },
  SET_OPERATION_SEARCH(state, val) {
    state.operationSearch = val
  },
  SET_SIDEBAR_VISIBILITY(state, bool: boolean) {
    state.showSidebar = bool
  },
  SET_OPERATIONS(state, operations: IOperation[]) {
    state.operations = operations
  }
}

export const createOperationsStore = ({
  operations,
  questions
}: {
  operations: any
  questions: Array<Backend.Questionnaire.IQuestion>
}) => {
  const store = Vuex.createStore<IOperationsStore>({
    state: {
      operations: operations,
      selected_operation: null,
      questions: questions,
      showSidebar: false,
      ast: "{'ast':{}}",
      complexMode:
        window.localStorage.getItem('operations-complex-mode') === 'true',
      operationName: '',
      operationSearch: ''
    },
    actions,
    getters,
    mutations
  })
  synchronizeStoreField([AvvStore, store], 'operations')
  window.opStore = store
  return store
}

globalThis.createOperationsStore = createOperationsStore

interface SmartStore<T>
  extends Omit<Store<T>, 'dispatch' | 'commit' | 'getters'> {
  dispatch<ActionType extends keyof typeof actions>(
    action: ActionType,
    payload?: Parameters<(typeof actions)[ActionType]>[1]
  ): void
  commit<MutationType extends keyof typeof mutations>(
    mutation: MutationType,
    payload?: Parameters<(typeof mutations)[MutationType]>[1]
  ): void
  getters: { [P in keyof typeof getters]: ReturnType<(typeof getters)[P]> }
}

export const OPERATIONS_STORE: InjectionKey<SmartStore<IOperationsStore>> =
  'OperationsStore' as any
globalThis.OPERATIONS_STORE = OPERATIONS_STORE
